import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs'
import React from 'react'
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import PageBannerPolicy from "../components/atoms/PageBannerPolicy/PageBannerPolicy";
import PageTitle from "../components/atoms/PageTitle/PageTitle";
import * as styles from "./privacy.module.css";
import { graphql } from "gatsby";

const PrivacyPage = ({data}) => {
  const page = (data && data.termsPage) || false;

  return (
      <Layout>
        <Seo title={page && page.pageName} description={page.metaDescription} image={page.metaPreviewImage ? `https:${page.metaPreviewImage.file.url}` : ''}/>

        <PageBannerPolicy
          title={page && page.metaTitle}
          description={page && page.metaDescription}
        />
        <Container size="medium">
          <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {label:`${page && page.pageName}`}]}/>
          <PageTitle customMode={true}>{page && page.pageName}</PageTitle>
          <div className={styles.privacyBody}>
            {page && page.blocks.map((block,index) => (
              block.blocks.map((content, indexC) => (
                <div key={indexC} dangerouslySetInnerHTML={{
                  __html: content.content.childMarkdownRemark.html
                }}>
                </div>
              ))
            ))}
          </div>
        </Container>
      </Layout>
    );
}
 
export default PrivacyPage;

export const query = graphql`
    query ContentfulPageTermsConditions {
        termsPage: contentfulPage(slug: {eq: "terms-and-conditions"}) {
            pageName
            pageTitle
            metaCanonicalLink
            metaDescription
            metaTitle
            metaPreviewImage{
              file {
                url
              }
            }
            blocks {
                blocks {
                    ... on ContentfulBlockContent {
                        name
                        content {
                            childMarkdownRemark {
                            html
                            }
                        }
                    }
                }
            }
        }
    }
`